
export default {
  setup() {
    return {
      colors: {
        backdrop: "#262626",
        nav: "#171717",
        txt: "#ffffff",
        card: "#404040",
        progress: "#10b981",
        delete: "#dc2626"
      }
    }
  }
}
